<template>
    <div class="settings" @scroll="onScroll">
        <div class="page-header">
            <StackRouterHeaderBar
                :class="{ 'show-title': showTitle }"
                :title="$translate('SETTINGS')"
                :showTitle="showTitle"
            />
            <div class="title" v-html="$translate('SETTINGS')" />
        </div>
        <div class="body">
            <div class="section" :key="section.title" v-for="section in sections">
                <div class="section-title" v-html="$translate(section.title)" />
                <div :key="item.key" v-for="item in section.items">
                    <div class="item flex-row items-center flex-between" @click="item.handler && item.handler()">
                        <div class="item-title" v-html="$translate(item.key.toUpperCase())" />
                        <div v-if="item.hasOwnProperty('value')">
                            <Toggler v-if="typeof item.value === 'boolean'" v-model="item.value" class="no-touch" />
                            <div v-else v-html="item.value" />
                        </div>
                        <!-- <i v-else class="material-icons"> chevron_right </i> -->
                    </div>
                    <img class="example-img m-b-12" v-if="item.img" :src="item.img" alt="" />
                </div>
            </div>
        </div>
        <div class="app-version" v-html="appVersion" />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'SettingsPage',
    data: () => ({
        snackbar: false,
        showTitle: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        sections() {
            if (!this.me) return

            return [
                {
                    title: 'NOTIFICATIONS',
                    items: [
                        {
                            key: 'message_accepted',
                            value: this.me.message_accepted,
                            handler: () => this.update('message_accepted'),
                        },
                        {
                            key: 'notified',
                            value: this.me.notified,
                            handler: () => this.update('notified'),
                        },
                        {
                            key: 'NOTIFY_DISPLAY',
                            handler: () => {
                                if (!this.me.notified) {
                                    this.$toast.success('먼저 앱 알림 설정을 해주세요')
                                    return
                                }
                                this.$stackRouter.push({ name: 'NotificationDisplayPage' })
                            },
                        },
                    ],
                },
                {
                    title: 'FUNCTIONS',
                    items: [
                        {
                            key: 'suggested',
                            value: this.me.suggested,
                            handler: () => this.update('suggested'),
                        },
                        {
                            key: 'sms_accepted',
                            value: this.me.sms_accepted,
                            handler: () => this.update('sms_accepted'),
                        },
                    ],
                },
                {
                    title: 'CHATROOM',
                    items: [
                        {
                            key: 'kakao_mode',
                            img: require('@/assets/images/kakao_mode_example.png'),
                            value: this.$store.getters.kakaoMode,
                            handler: () => {
                                const changed = !this.$store.getters.kakaoMode
                                changed
                                    ? localStorage.setItem('kakao_mode', changed)
                                    : localStorage.removeItem('kakao_mode')
                                this.$store.commit('setKakaoMode', changed)
                                this.$toast.success(`카카오톡 스킨이 ${changed ? '적용' : '해제'} 되었어요`)

                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'Click_ChangeKakaoSkin',
                                        option: {
                                            value: this.$kakaoMode() ? 'on' : 'off',
                                            channel: 'settingPage',
                                        },
                                    },
                                })
                            },
                        },
                    ],
                },
                {
                    title: 'ACCOUNT',
                    items: [
                        {
                            key: 'dormant',
                            handler: () => this.$stackRouter.push({ name: 'DormantPage' }),
                        },
                        {
                            key: 'change_password',
                            handler: () => this.$stackRouter.push({ name: 'ChangePasswordPage' }),
                        },
                        {
                            key: 'logout',
                            handler: () => this.askLogout(),
                        },
                        {
                            key: 'dropout',
                            handler: () => this.askDropout(),
                        },
                    ],
                },
            ]
        },
        appVersion() {
            const { app_version: version } = this.$store.getters.device || {}

            return `앱 버전 ${version || ''}`
        },
    },
    methods: {
        onScroll(e) {
            this.showTitle = e.target.scrollTop > 52
        },
        askLogout() {
            this.$modal
                .basic({
                    title: 'LOGOUT',
                    body: this.$translate('LOGOUT_BODY'),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'LOGOUT',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 1) this.$store.dispatch('signOut')
                })
        },
        toggleLocale() {
            this.$store.dispatch('toggleLocale')
        },
        async update(key) {
            const payload = this.me
            payload[key] = !payload[key]
            try {
                await userService.update(payload)
                this.$toast.success('UPDATED')
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.$store.dispatch('loadMe')
        },
        askDropout() {
            const onConfirm = async () => {
                const ret = await userService.checkDropout()

                if (ret.shared === 1) {
                    this.$modal
                        .basic({
                            title: 'MODAL_DEFAULT_TITLE',
                            body: ret.msg,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-default',
                                },
                            ],
                        })
                        .then(() => {
                            this.$stackRouter.pop()
                            this.$router.push({ name: 'MyDatingPage' })
                        })
                } else {
                    this.$modal.custom({
                        component: 'ModalDropout',
                    })
                }
            }
            this.$modal
                .basic({
                    title: 'DROPOUT_TITLE',
                    body: this.$translate('DROPOUT_BODY'),
                    buttons: [
                        {
                            label: 'DROPOUT',
                            class: 'btn-default',
                        },
                        {
                            label: 'WAIT_BTN',
                            class: 'btn-default',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 0) {
                        onConfirm()
                    }
                })
        },
    },
}
</script>
<style scoped lang="scss">
::v-deep .stack-router-header-bar {
    .left-btn > .material-icons {
        color: $grey-08;
    }

    &.show-title {
        z-index: 3;
        position: fixed;
        top: 0;
        background-color: white;
        border-bottom: solid 1px $grey-03;
        margin-bottom: 16px;

        .material-icons {
            color: $grey-08 !important;
        }
    }
}
</style>
